.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #01b3a7;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

img {
  pointer-events: none;
}

.elementToFadeInAndOut {
  -webkit-animation: fadeinout 4s linear forwards;
  animation: fadeinout 4s linear forwards;
  opacity: 0;
}

@-webkit-keyframes fadeinout {
50% { opacity: 1; }
}

@keyframes fadeinout {
100% { opacity: 1; }
}

.textombrenoir {
  text-shadow: 1px 1px 2px black;
}

.navbar-edit {
  background-color: #162e44;
  position: fixed;
}

.color-me{
  color:blue;
}

.nav-link {
  color: white;
  font-size: 1.1em; 
  transition: 200ms;
}

.nav-link--active {
  color: #01b3a7 !important;
  transform: scale(1.1);
  font-weight: 10px;
}

.nav-link:hover {
  color: #01b3a7 !important;
  transform: scale(1.1);
  text-decoration: none !important;
  font-weight: 18px;
  font-size: 1.5em;
}

.togglecolor{
  color:white !important;
  background-color: #01b3a7 !important;
}

.nav-item.dropdown .dropdown-menu {
  background-color: #162e44 !important;
  opacity: 0.8;
  text-decoration: none;
  color: #01b3a7 !important;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
  background-color: #162e44 !important;
  opacity: 0.8;
  text-decoration: none;
  color: #01b3a7 !important;
}

.menudropdownhover:hover{
  margin-left: 10px;
  border: 0px;
  width: 50px;
  height: 50px;
  background: #162e44!important;
  background: linear-gradient(left, #162e44, #162e44);
  background: -moz-linear-gradient(left, #162e44, #162e44)!important;
  background: -webkit-gradient(linear, 100% 100%, 0% 0%, from(#162e44), to(#162e44))!important;
  color: #01b3a7 !important;
}

.swiper2 {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  min-height:100vh !important;
  min-height: -webkit-fill-available !important;
}

.fullscreenswiper{
  object-fit: contain;
  flex-shrink:0;
    min-width:100%;
    min-height: 100vh;
    min-height: -webkit-fill-available;
}

.swiper-slide {
  text-align: left;
  font-size: 1.5rem;
  background: transparent;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  /* Center slide text vertically */
  /*-webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;*/
}

.swiper-slide img {
  display: block;
  width: 100vh;
  height: 100vh;
  object-fit: cover;
}

span.swiper-pagination-bullet {
  background-color: #a7b3b5;
  opacity: 1;
  text-shadow: 2px 2px 4px black;
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #01b3a7;
  opacity: 1;
  box-shadow: #282c34;
  text-shadow: 2px 2px 4px black;
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.listedata {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  align-content: space-between;
  align-items: center;
  justify-content: center;
}

.cardlist{
  border: 2px solid #01b3a7;
  border-radius: 8px;
  margin: 20px;
}

.cardlistopinion{
  border: 2px solid #ffbc0b;
  border-radius: 8px;
  margin: 20px;
}

.listedatamobile {
  height: auto;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
}

.cardlistmobile{
  border: 2px solid #01b3a7;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  height: auto;
}

.buttondetails {
  align-items: center;
  background-color: #fff;
  border: 2px solid #000;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  display: inline-flex;
  fill: #000;
  font-family: Inter,sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  justify-content: center;
  letter-spacing: -.8px;
  line-height: 24px;
  min-width: 140px;
  outline: 0;
  padding: 0 17px;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  border-radius: 8px;
}

.buttondetails:focus {
  color: #171e29;
}

.buttondetails:hover {
  background-color: #01b3a7;
  border-color: #01b3a7;
  border-radius: 8px;
  color: white;
  fill: #01b3a7;
}

.buttondetails:active {
  border-radius: 8px;
  background-color: #01b3a7;
  border-color: #01b3a7;
  color: white;
  fill: #01b3a7;
}

@media (min-width: 768px) {
  .buttondetails {
    min-width: 170px;
  }
}

.centerdata {
  align-items: center;
  justify-content: center;
}

.footer{
  background-color: #162e44;
}

.contactlist {
  display: flex;
  justify-content: space-between;
}

a {
  color: white !important;
  transform: scale(1.1);
  text-decoration: none !important;
  font-weight: 18px;
  font-size: 1em;
}

a:hover {
  color: #01b3a7 !important;
  transform: scale(1.1);
  text-decoration: none !important;
  font-weight: 20px;
  font-size: 1.2em;
}

.bntsign{
  border: none;
  outline: none;
  background-color: #01b3a7;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.bnttop {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 1000;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #01b3a7;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.bnttop:hover {
  color: white;
  border-radius: 10px;
}

.menu {
  background-color:#162e44;
  opacity: 0.8;
  width: 100%;
}

#tours{
  padding-top: 74px;
  margin-top: -74px;
  
}

#gallery{
  padding-top: 74px;
  margin-top: -74px;
}

#contact{
  padding-top: 74px;
  margin-top: -74px;
}

.modal-dialog-mobile{
  height: 80vh;
}

.modal-infotype {
  height: 40%;
}

.image-gallery {
  width: 100%;
  height: 90vh;
}

.image-gallery-slide img {
  width: 100%;
  height: 50vh;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}

.fullscreen .image-gallery-slide img {
  max-height: 60vh;
  height: 80vh;
}

.main{
  max-height: 250px;
  width: 100vh;
  text-align: left;
}

.main > *{
  width: 80%;
  margin-left:10vh;
}

.editorstyle {
  background-color:lightgray;
  padding: 1rem;
  border: 1px solid #ccc;
}

.public-DraftEditor-content{
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 1em;
  height: 100px;
  overflow-x: hidden;
  overflow-y: auto;
}





